import React, { useState } from "react";
import PageLayout from "../components/PageLayout";
import Modal from "../components/Modal";
import ClickButton from "../components/ClickButton";
import { Trans } from "react-i18next";
import styled from "styled-components";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { goTo } from "../utilities/utils";

export default function Account() {
  const { t } = useTranslation();

  const [country, setCountry] = useState("CA");

  const onRadioClick = (value) => {
    setCountry(value);
  }

  const onClickNext = () => {
    if (country === "CA") {
      location.href="https://myaccount.gophonebox.com/login";
    }
    if (country === "US") {
      location.href="https://us.myaccount.gophonebox.com/"
    }
  }

  return (
    <PageLayout>
      <Placeholder/>
      <Modal
        onClose={() => {
          goTo('/');
        }}
        title={
          <Title>
            <Trans>Where is your phone number from?</Trans>
          </Title>
        }
        body={
          <Layout>
            <RadioForm>
              <RadioButton>
                <input
                  type="radio"
                  name="country"
                  value="Canada"
                  checked={country === "CA"}
                  onChange={() => onRadioClick("CA")}
                />
                <Trans>Canada</Trans>
              </RadioButton>
              <RadioButton>
                <input
                  type="radio"
                  name="country"
                  value="USA"
                  onChange={() => onRadioClick("US")}
                />
                <Trans>USA</Trans>
              </RadioButton>
            </RadioForm>
            <Container>
              <ClickButton
                text={t("Next")}
                color={"blue"}
                length={""}
                buttonLength={""}
                onClick={onClickNext}
              ></ClickButton>
            </Container>
          </Layout>
        }
        footer={
          <FooterDiv>
            <img style={{ width: "100%" }} src="/images/account-modal.png" />
          </FooterDiv>
        }
        borderRadius={"10px"}
      />
    </PageLayout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
`;

const Title = styled.div`
  color: #0494ca;
`;

const RadioForm = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 30%;
`;

const RadioButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-right: 2rem;
`;

const Container = styled.div`
  margin-top: 70px;
  margin-bottom: 30px;
`;

const Placeholder = styled.div`
  height: 50vh;
`

const FooterDiv = styled.div`
  height: 100%;
  border-radius: 0 0 5px 5px;
`;